import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationHubSpot {
    id: number,
    quiz: number,
    is_enabled: boolean
}

export interface IntegrationHubSpotAoutUrl {
    auth_url: "string"
}

export const integrationHubSpotApi = createApi({
    reducerPath: "integrationHubSpotApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationHubSpot"],
    endpoints: (builder) => ({
        getHubSpotByQuizId: builder.query<IntegrationHubSpot, number>({
            query: (quizId) => ({ url: `hubspot-integration/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationHubSpot", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationHubSpotApi.util.upsertQueryData('getHubSpotByQuizId', arg, null as any));
                    }

                    if (err.error && err.error.status === 404) {
                        dispatch(integrationHubSpotApi.endpoints.getHubSpotUrlByQuizId.initiate(arg));
                    }
                }
            },
        }),
        getHubSpotUrlByQuizId: builder.query<IntegrationHubSpotAoutUrl, number>({
            query: (quizId) => ({ url: `hubspot/auth-link/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationHubSpot", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationHubSpotApi.util.upsertQueryData('getHubSpotUrlByQuizId', arg, null as any));
                    }
                }
            },
        }),
        updateHubSpot: builder.mutation<
            IntegrationHubSpot,
            PartialBy<IntegrationHubSpot, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `hubspot-integration/${quiz}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheKeyCrm({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheKeyCrm({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationHubSpotApi.util.invalidateTags([{ type: "IntegrationHubSpot", id: quiz }])
                    );
                }
            },
        }),
        deleteHubSpot: builder.mutation<void, number>({
            query: (quizId) => ({ url: `hubspot-integration/${quizId}/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
              return  [{ type: "IntegrationHubSpot", id }];
             },
        }),
    }),
});

export const {
    useGetHubSpotByQuizIdQuery,
    useGetHubSpotUrlByQuizIdQuery,
    useUpdateHubSpotMutation,
    useDeleteHubSpotMutation
} = integrationHubSpotApi;

export const updateCacheKeyCrm = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationHubSpot, "quiz">) => {
    return integrationHubSpotApi.util.updateQueryData(
        "getHubSpotByQuizId",
        quiz,
        (draft: Partial<IntegrationHubSpot>) => {
            Object.assign(draft, patch);
        }
    );
};
