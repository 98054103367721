import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationGetResponse {
    id: number
    quiz: number
    api_key: string
    is_enabled: boolean
    campaign_id: string
}

export const integrationGetResponseApi = createApi({
    reducerPath: "integrationGetResponseApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationGetResponse"],
    endpoints: (builder) => ({
        getGetResponseByQuizId: builder.query<IntegrationGetResponse, number>({
            query: (quizId) => ({ url: `get-response-integration/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationGetResponse", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationGetResponseApi.util.upsertQueryData('getGetResponseByQuizId', arg, null as any));
                    }
                }
            },
        }),
        createGetResponse: builder.mutation<
            IntegrationGetResponse,
            Omit<IntegrationGetResponse, "id">
        >({
            query: (data) => ({ url: "get-response-integration/", method: "POST", data }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(integrationGetResponseApi.util.invalidateTags([{ type: "IntegrationGetResponse", id: data.quiz }]));
            },
        }),
        updateGetResponse: builder.mutation<
            IntegrationGetResponse,
            PartialBy<IntegrationGetResponse, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `get-response-integration/${quiz}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheGetResponse({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheGetResponse({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationGetResponseApi.util.invalidateTags([{ type: "IntegrationGetResponse", id: quiz }])
                    );
                }
            },
        }),
        deleteGetResponse: builder.mutation<void, number>({
            query: (quizId) => ({ url: `get-response-integration/${quizId}/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
                return [{ type: "IntegrationGetResponse", id }];
            },
        }),
    }),
});

export const {
    useGetGetResponseByQuizIdQuery,
    useCreateGetResponseMutation,
    useUpdateGetResponseMutation,
    useDeleteGetResponseMutation,

} = integrationGetResponseApi;

export const updateCacheGetResponse = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationGetResponse, "quiz">) => {
    return integrationGetResponseApi.util.updateQueryData(
        "getGetResponseByQuizId",
        quiz,
        (draft: Partial<IntegrationGetResponse>) => {
            Object.assign(draft, patch);
        }
    );
};
