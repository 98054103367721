import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationSendPulse {
    id: number
    quiz: number
    api_id: string
    is_enabled: boolean
    address_book_id: string
    api_secret: string
}

export const integrationSendPulseApi = createApi({
    reducerPath: "integrationSendPulseApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationSendPulse"],
    endpoints: (builder) => ({
        getSendPulseByQuizId: builder.query<IntegrationSendPulse, number>({
            query: (quizId) => ({ url: `sendpulse-integration/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationSendPulse", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationSendPulseApi.util.upsertQueryData('getSendPulseByQuizId', arg, null as any));
                    }
                }
            },
        }),
        createSendPulse: builder.mutation<
            IntegrationSendPulse,
            Omit<IntegrationSendPulse, "id">
        >({
            query: (data) => ({ url: "sendpulse-integration/", method: "POST", data }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(integrationSendPulseApi.util.invalidateTags([{ type: "IntegrationSendPulse", id: data.quiz }]));
            },
        }),
        updateSendPulse: builder.mutation<
            IntegrationSendPulse,
            PartialBy<IntegrationSendPulse, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `sendpulse-integration/${quiz}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheSendPulse({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheSendPulse({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationSendPulseApi.util.invalidateTags([{ type: "IntegrationSendPulse", id: quiz }])
                    );
                }
            },
        }),
        deleteSendPulse: builder.mutation<void, number>({
            query: (quizId) => ({ url: `sendpulse-integration/${quizId}/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
                return [{ type: "IntegrationSendPulse", id }];
            },
        }),
    }),
});

export const {
    useGetSendPulseByQuizIdQuery,
    useCreateSendPulseMutation,
    useUpdateSendPulseMutation,
    useDeleteSendPulseMutation,
} = integrationSendPulseApi;

export const updateCacheSendPulse = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationSendPulse, "quiz">) => {
    return integrationSendPulseApi.util.updateQueryData(
        "getSendPulseByQuizId",
        quiz,
        (draft: Partial<IntegrationSendPulse>) => {
            Object.assign(draft, patch);
        }
    );
};
