import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationMailchimp {
    id: number
    quiz: number
    api_key: string
    is_enabled: boolean
    list_id: string
}

export const integrationMailchimpApi = createApi({
    reducerPath: "integrationMailchimpApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationMailchimp"],
    endpoints: (builder) => ({
        getMailchimpByQuizId: builder.query<IntegrationMailchimp, number>({
            query: (quizId) => ({ url: `mailchimp-integration/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationMailchimp", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationMailchimpApi.util.upsertQueryData('getMailchimpByQuizId', arg, null as any));
                    }
                }
            },
        }),
        createMailchimp: builder.mutation<
            IntegrationMailchimp,
            Omit<IntegrationMailchimp, "id">
        >({
            query: (data) => ({ url: "mailchimp-integration/", method: "POST", data }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(integrationMailchimpApi.util.invalidateTags([{ type: "IntegrationMailchimp", id: data.quiz }]));
            },
        }),
        updateMailchimp: builder.mutation<
            IntegrationMailchimp,
            PartialBy<IntegrationMailchimp, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `mailchimp-integration/${quiz}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheMailchimp({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheMailchimp({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationMailchimpApi.util.invalidateTags([{ type: "IntegrationMailchimp", id: quiz }])
                    );
                }
            },
        }),
        deleteMailchimp: builder.mutation<void, number>({
            query: (quizId) => ({ url: `mailchimp-integration/${quizId}/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
                return [{ type: "IntegrationMailchimp", id }];
            },
        }),
    }),
});

export const {
    useGetMailchimpByQuizIdQuery,
    useCreateMailchimpMutation,
    useUpdateMailchimpMutation,
    useDeleteMailchimpMutation,
} = integrationMailchimpApi;

export const updateCacheMailchimp = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationMailchimp, "quiz">) => {
    return integrationMailchimpApi.util.updateQueryData(
        "getMailchimpByQuizId",
        quiz,
        (draft: Partial<IntegrationMailchimp>) => {
            Object.assign(draft, patch);
        }
    );
};
